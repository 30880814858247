import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FaMapMarkerAlt, FaEnvelope, FaLink, FaPhone  } from 'react-icons/fa'
import './index.module.css'

export default () => (
  <StaticQuery
    query={graphql`
      query DetailsQuery {
        site {
          siteMetadata {
            home {
              location
              email
              website
            }
          }
        }
      }
    `}
    render={data => (
      <div styleName='wrapper'>
        <span><FaMapMarkerAlt color='#ccd1d6' /></span>
        <span className='name'>{data.site.siteMetadata.home.location}</span>
        <span><FaEnvelope color='#ccd1d6' /></span>
        <span className='desc'>{data.site.siteMetadata.home.email}</span>
        <span><FaPhone color='#ccd1d6' /></span>
        <span className='desc'>267.<span style={{ display: 'none'}}>HGJFHJFJH</span>235.<span style={{ display: 'none'}}>TUYIT</span>4019</span>
        <span><FaLink color='#ccd1d6' /></span>
        <span className='desc'><a href={data.site.siteMetadata.home.website}>{data.site.siteMetadata.home.website}</a></span>
      </div>
    )}
  />
)
