import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { FaTwitter, FaLinkedinIn, FaGithubAlt, FaStackOverflow } from 'react-icons/fa'
import './index.module.css'

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <header className='header'>
        <div styleName='container'>
          <div styleName='profile-wrapper'>
            <img className='profile-image' src='/assets/images/efosa-oyegun-profile.png' alt='Efosa Oyegun' />
          </div>
          <div className='profile-content float-left'>
            <h1 className='name'>{data.site.siteMetadata.home.title}</h1>
            <h2 className='desc'>{data.site.siteMetadata.home.description}</h2>
            <ul styleName='social'>
              <li className='list-inline-item'><a href='https://twitter.com/efosa' rel='noopener noreferrer' target='_blank'><FaTwitter /></a></li>
              {/* <li class="list-inline-item"><a href="#"><i class="fab fa-google-plus-g"></i></a></li> */}
              <li className='list-inline-item'><a href='https://www.linkedin.com/in/efosa/' rel='noopener noreferrer' target='_blank'><FaLinkedinIn /></a></li>
              <li className='list-inline-item'><a href='https://github.com/efosao' rel='noopener noreferrer' target='_blank'><FaGithubAlt /></a></li>
              <li className='list-inline-item'><a href='https://stackexchange.com/users/70552/efosa' rel='noopener noreferrer' target='_blank'><FaStackOverflow /></a></li>
              {/* <li class="list-inline-item last-item"><a href="#"><i class="fab fa-codepen"></i></a></li> */}
            </ul>
          </div>
          <div styleName='contact-wrapper'>
            <a styleName='btn' href='https://www.linkedin.com/in/efosa/' rel='noopener noreferrer' target='_blank'>
              <i className='fas fa-paper-plane' />Contact Me</a>
          </div>
        </div>
      </header>
    )}
  />
)
