import React from "react";
import Helmet from "react-helmet";
// import { useStaticQuery, graphql } from 'gatsby'
import "./index.module.css";
import Hero from "../hero";
import Details from "../details";

export default ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="site-wrapper">
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/safari-pinned-tab.svg"
          color="#716b64"
        />
        <meta name="msapplication-TileColor" content="#716b64" />
        <meta name="theme-color" content="#ffffff" />

        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,300italic,400italic"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
          rel="stylesheet"
          type="text/css"
        />
        <link id="theme-style" rel="stylesheet" href="/assets/css/styles.css" />
      </Helmet>
      {/* <header className="site-header">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
        <Navigation />
      </header> */}

      <div styleName="container">
        <Hero />
        <div styleName="layout_container">
          <section>{children}</section>
          <section>
            <div>
              <Details />
            </div>
            {/* <div>Box 2</div> */}
          </section>
        </div>
      </div>

      <footer styleName="site-footer">
        <p>&copy; 2022 Efosa Oyegun</p>
        <div>
          <a href="https://vauntly.com/">Vauntly Job Search</a>
          <span style={{ margin: "0 5px" }}>&bull;</span>
          <a href="https://vauntly.com/t/react--senior">
            Jobs for Senior React Engineers
          </a>
          <span style={{ margin: "0 5px" }}>&bull;</span>
          <a href="https://vauntly.com/t/rust">Jobs for Rust Engineers</a>
        </div>
      </footer>
    </div>
  );
};
